import React from "react"

import Layout from "../components/layout"
import BigLink from "../components/bigLink"

const NotFoundPage = (props) => (
  <Layout title="Ooooops..." slug="404"> 
  <div className="grid-row" style={{gap: "3rem"}}>
    <img style={{
      borderRadius: "50%"
    }}
    src="https://res.cloudinary.com/altreiter/image/upload/c_scale,e_tint:100:22282c:00b8a9:white,f_auto,q_auto:best,w_640/v1590613145/works/tri/stormtrooperOld_lvbhob.jpg"></img>
    <div>
      <h2>This is not the page you are looking for!</h2>
      <BigLink to="/">Go Back Home</BigLink>
    </div>
  </div>
  </Layout>
)

export default NotFoundPage
